import React from "react";
import { v4 as uuidv4 } from "uuid";
import Styled from "styled-components";

import Testimonial from "./Testimonial";
import { TESTIMONIALS } from "@config";

const Container = Styled.div`
    margin: 2rem;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    grid-gap: 20px;
`;

const TestimonialGallery = () => {
  const renderGallery = () => {
    const gallery = [];
    const arr = TESTIMONIALS.sort(() => Math.random() - 0.5);
    for (let i = 0; i < arr.length; i++) {
      const { name, description } = arr[i];
      gallery.push(<Testimonial key={uuidv4()} name={name} description={description} />);
    }
    return <>{gallery}</>;
  };
  return <Container>{renderGallery()}</Container>;
};

export default TestimonialGallery;
