import React from "react";
import Styled from "styled-components";

const Container = Styled.div`
    background-color: ${({ theme }) => theme.gainsboro};
    padding: 10px;
    place-self: stretch;
    justify-self: stretch;
    transition: all 250ms linear;
    cursor: pointer;
    h3 {
        margin: 0.5rem 0px;
        font-family: ${({ theme }) => theme.fontSecondary};
        color: ${({ theme }) => theme.black};
    }
    h4 {
        margin: 0.5rem 0px;
        font-family: ${({ theme }) => theme.fontSecondary};
        color: ${({ theme }) => theme.black};
    }
    &:hover {
        background-color: ${({ theme }) => theme.black};
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
        h3, h4 {
            color: ${({ theme }) => theme.white};
        }
    }
`;

const Testimonial = ({ name, description }) => (
  <Container>
    <h3>{name}</h3>
    <h4>{description}</h4>
  </Container>
);

export default Testimonial;
