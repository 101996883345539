import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import { Section } from "@components/StyledComponents";
import TestimonialGallery from "@components/TestimonialGallery";

const TestimonialsPage = ({ data }) => {
  const header = data.header.childImageSharp.fluid;
  return (
    <Layout
      seoTitle="Testimonials"
      seoDescription="Testimonials For Cassandra Lee & Co"
      fluid={header}
      alt="Testimonials For Cassandra Lee & Co"
      headerTitle="Buy.Sell.Invest"
      headerSubTitle="Your home is my commitment"
    >
      <Section>
        <h2>What my clients have to say</h2>
        <hr />
        <TestimonialGallery />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query TestimonialsQuery {
    header: file(relativePath: { eq: "unsplash_4_1200x798.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default TestimonialsPage;
